//
// solana-variables.scss
// Use this to overwrite Bootstrap and Dashkit variables
//

// Example of a variable override to change Dashkit's background color
// Remove the "//" to comment it in and see it in action!
$body-bg: #161b19 !default;

$black: #232323;
$white: #fff !default;
$gray-600: #86b8b6;
$gray-600-dark: #343a37 !default;
$gray-700-dark: #282d2b !default;
$gray-800-dark: #1e2423 !default;
$black-dark: #141816 !default;
$input-placeholder-color: #ccc !default;

$primary: #1dd79b !default;
$success: #26e97e !default;
$warning: #fa62fc !default;
$card-border-color: $gray-700-dark !default;
$card-outline-color: #111 !default;

$input-bg: transparent !default;
$input-border-color: $gray-600-dark !default;
$input-group-addon-color: $white !default;

$theme-colors: (
  "black": $black,
  "gray": $gray-600,
  "gray-dark": $gray-800-dark,
);
