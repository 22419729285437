//
// solana.scss
// Use this to write your custom SCSS
//

code, pre {
  background-color: $black-dark;
  color: $white;
}

ul.log-messages {
  background-color: $black-dark;
  color: $white;
}

.form-control {
  border-color: $input-border-color;
}

.input-group .input-group-text {
  border-color: $input-border-color;
}

.navbar {
  border-bottom: 1px solid $card-outline-color;
}

.search-bar__placeholder {
  color: $input-placeholder-color !important;
}

.search-bar__control {
  background-color: $gray-800-dark !important;
  border-color: $card-outline-color !important;
  box-shadow: $card-box-shadow !important;
  min-height: 52px !important;

  .search-bar__input {
    color: $white !important;
  }
}

.search-bar__menu {
  background-color: $gray-800-dark !important;
  border: 1px solid $card-outline-color !important;
  box-shadow: $card-box-shadow !important;

  .search-bar__option {
    cursor: pointer;

    &.search-bar__option--is-focused {
      background-color: transparent !important;
    }

    &:hover {
      background-color: $gray-700-dark !important;
    }
  }
}
